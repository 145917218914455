import * as React from 'react'
import { graphql } from 'gatsby'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider';

import DefaultHeader from '../../../../components/default-header'
import ExecutiveLeadership from '../../../../components/executiveLeadership';
import Footer from '../../../../components/footer'
import GdprPanel from "../../../../components/gdpr"
import Seo from '../../../../components/seo'
import SpeakWithAdmissions from '../../../../components/speakWithAdmissions';
import Navigation from '../../../../components/navigation'

import '../../../../styles/executive-leadership.scss'
import NewsletterSignup from '../../../../components/newsletterSignUp';

const Page = ({ data }) => {

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader type="xl" headline={data.mdx.frontmatter.headline} heroBackground={data.mdx.frontmatter.heroBackground} heroMobileBackground={data.mdx.frontmatter.heroMobileBackground} subHeadline={data.mdx.frontmatter.subheadline} />
            <div id="body" className="el">
                <Container fluid="lg" className="py-lg-5 py-4">
                    <Row className="pt-lg-5 pt-4">
                        <Col xs={12} className="mb-lg-5 mb-4 pb-lg-5 pb-4">
                            <h2 className="mb-3 pb-2">Board of Trustees</h2>
                            <div className="green-border mb-lg-5 mb-4"></div>
                            <ExecutiveLeadership type="board" />
                            <blockquote>
                            Meridian’s Chief Academic Officer Melissa Schwartz also serves on the University’s Board of Trustees.
                            </blockquote>
                        </Col>
                        <Col xs={12} className="mb-lg-5 mb-4 pb-lg-5 pb-4">
                            <h2 className="mb-3 pb-2">Administrative and Academic Leadership</h2>
                            <div className="blue-border mb-lg-5 mb-4"></div>
                            <ExecutiveLeadership type="acad_admin" />
                        </Col>
                        <Col xs={12}>
                            <div id="bottom-contact">
                                <Row className="pb-4 pb-xl-5 justify-content-center">
                                    <Col md={6} className="mb-0 mb-md-4 mb-xl-0">
                                        <SpeakWithAdmissions menuType="bottom-blue"  />
                                    </Col> 
                                    <Col md={6}>
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image.webp" alt="learn more about meridian xl" className="img-fluid d-none d-xl-block" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-large.webp" alt="learn more about meridian lg" className="img-fluid d-none d-lg-block d-xl-none" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-medium.webp" alt="learn more about meridian md" className="img-fluid d-none d-md-block d-lg-none" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-small.webp" alt="learn more about meridian sm" className="img-fluid d-block d-md-none" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
)

export const query = graphql`
    query ExecutiveLeadershipPageQuery {
        mdx(fileAbsolutePath: {regex: "/executive-leadership.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }    
`

export default Page